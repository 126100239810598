import React, { useEffect } from "react"
import Layout from "components/layout"
import Seo from "components/seo"
import * as FadeIn from "components/fadein"
import PageLead from "../../../components/pageLead";
import Faq from "../../../components/faq";
import ContentFooter from "../../../components/contentFooter";
import WhyCoinLaundry from "../../../components/fc/whyCoinLaundry";
import WhyOkulab from "../../../components/fc/whyOkulab";


const FcPage = () => {
    useEffect(() => {
    }, [])

    return (
        <Layout>
            <div className="fc-page">
                <PageLead sub="SERVICE"
                          title="フランチャイズ事業"
                          description="Baluko Laundry Placeを通じて新しいランドリー文化を創造し、\n豊かなライフスタイルを世界に提供します。"
                          imageUrl="https://stgokulabweb.blob.core.windows.net/images/services/fc/fc_fc-buisiness.jpg"
                          linkUrl="/contact"
                />
                <div className="contents">
                    <FadeIn.Up>
                        <WhyCoinLaundry />
                    </FadeIn.Up>
                    <FadeIn.Up>
                        <WhyOkulab />
                    </FadeIn.Up>
                    <FadeIn.Up>
                        <Faq data='[
                            {
                                "q": "開店までの期間はどのくらいですか？",
                                "a": "諸条件により異なりますが、開店までの目安期間は約1～2ヶ月です。"
                            },
                            {
                                "q": "ランドリー業界未経験でも出店可能ですか？",
                                "a": "可能です。オープン～開店後の運営まで丁寧にサポートいたします。"
                            },
                            {
                                "q": "事前の市場調査や見積もり依頼などで費用が発生することはありますか？",
                                "a": "出店のご契約までに費用が発生することはありません。"
                            },
                            {
                                "q": "ランドリー出店の初期費用はどのくらいかかりますか？",
                                "a": "諸条件により異なりますがおおよそ2,800万円～出店が可能です。更に費用を抑えた出店の可能性もございますので、まずはお問い合わせください。"
                            },
                            {
                                "q": "所有物件がないのですが出店可能ですか？",
                                "a": "はい、可能です。ご希望エリアでの物件探しから承ります。"
                            },
                            {
                                "q": "他ブランドでコインランドリーを運営していますが、Balukoブランドの運営に変更可能ですか？",
                                "a": "既存ブランド様とのご契約条件をご確認の上、ご相談に応じております。"
                            },
                            {
                                "q": "融資先は紹介していただけますか？",
                                "a": "はい。融資実績のある金融機関様をご紹介いたします。メインバンク様にてご融資をされる場合も、必要資料などのサポートをいたします。"
                            }
                        ]'/>
                    </FadeIn.Up>
                    <FadeIn.Up>
                        <ContentFooter
                            description="200店舗以上の実績を基にしたノウハウで、無料相談も受付中。\nお気軽にお問い合わせください。"
                            buttonText="お問い合わせ"
                            href="/contact"
                        />
                    </FadeIn.Up>
                </div>
            </div>
        </Layout>
    )
}
export const Head = () => (
    <Seo
        title="フランチャイズ事業"
        description="コインランドリーBaluko Laundry PlaceのFC加盟店を募集しています。200店舗以上の出店実績をもとにしたノウハウでコインランドリーの出店と運営をサポートいたします。"
        topFlag={true}
    />
)

export default FcPage
