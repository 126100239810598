import React from "react"

const WhyCoinLaundry = props => {
    return (
        <section className="why-coin-laundry-wrap">
            <div className="why-coin-laundry-header-wrap">
                <p className="number">1</p>
                <h2>なぜいま、コインランドリーか？</h2>
                <div className="space"></div>
            </div>
            <div className="why-coin-laundry-body-wrap">
                <div className="space"></div>
                <div className="contents-body-wrap">
                    <h3 className="title-wrap">
                        <span className="title">1-1 ニーズの拡大</span><span className="sub">Growing Needs</span>
                        <hr className="title-underline" />
                    </h3>
                    <p className="description">
                        ライフスタイルの変化、人口動態の変化によりコインランドリーへのニーズは高まり、<br />
                        今後もこの傾向が継続していくことが見込まれます。
                    </p>
                    <h4 className="sub-title">コインランドリーを取り巻くマクロトレンド</h4>
                    <div className="sub-contents">
                        <div className="trends">
                            <div className="trend">
                                <h5 className="trend-title">ライフスタイルの変化</h5>
                                <div className="trend-body">
                                    <img src="https://stgokulabweb.blob.core.windows.net/images/services/fc/trend01.jpg"
                                         alt="Trend 01"/>
                                    <ul>
                                        <li>女性の社会進出=共働き世帯の増加</li>
                                        <li>時間節約型サービスの増加 （家事代行・食品宅配など）</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="trend">
                                <h5 className="trend-title">人口動態の変化</h5>
                                <div className="trend-body">
                                    <img src="https://stgokulabweb.blob.core.windows.net/images/services/fc/trend02.jpg"
                                         alt="Trend 02"/>
                                    <ul>
                                        <li>高齢化社会</li>
                                        <li>単身世帯の増加</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <img src="https://stgokulabweb.blob.core.windows.net/images/services/fc/down_arrow.svg"
                             alt="Arrow"
                        className="arrow-image"/>
                        <div className="result-text-wrap">
                            <h5 className="title">洗濯を含む、家事労働に<br className="sp" />割ける時間の減少</h5>
                            <p className="description">さらに社会的な衛生意識の高まりにより、新たなニーズが生まれています。</p>
                        </div>
                    </div>
                    <h4 className="sub-title">コインランドリーの新たなニーズ</h4>
                    <div className="sub-contents bg-blue-green">
                        <div className="needs-wrap pc">
                            <div className="needs">
                                <img src="https://stgokulabweb.blob.core.windows.net/images/services/fc/fc_needs01.png"
                                     alt="Needs 01"/>
                                <p className="description">アレルギー、ウイルス、花粉、PM2.5など環境衛生に対するニーズの高まり</p>
                            </div>
                            <div className="needs">
                                <img src="https://stgokulabweb.blob.core.windows.net/images/services/fc/fc_needs02.png"
                                     alt="Needs 02"/>
                                <p className="description">自宅で洗濯できない布団など<br />大物洗濯に対するニーズの高まり</p>
                            </div>
                            <div className="needs">
                                <img src="https://stgokulabweb.blob.core.windows.net/images/services/fc/fc_needs03.png"
                                     alt="Needs 03"/>
                                <p className="description">スニーカーなど衣類以外の<br />洗濯ニーズの高まり</p>
                            </div>
                        </div>
                        <div className="needs-wrap sp">
                            <div className="needs">
                                <p className="description">
                                    アレルギー、ウイルス、花粉、PM2.5など環境衛生に対するニーズの高まり
                                </p>
                                <img src="https://stgokulabweb.blob.core.windows.net/images/services/fc/fc_needs01.png"
                                     alt="Needs 01"/>
                            </div>
                            <hr />
                            <div className="needs">
                                <p className="description">自宅で洗濯できない布団など<br />大物洗濯に対するニーズの高まり</p>
                                <img src="https://stgokulabweb.blob.core.windows.net/images/services/fc/fc_needs02.png"
                                     alt="Needs 02"/>
                            </div>
                            <hr />
                            <div className="needs">
                                <p className="description">スニーカーなど衣類以外の<br />洗濯ニーズの高まり</p>
                                <img src="https://stgokulabweb.blob.core.windows.net/images/services/fc/fc_needs03.png"
                                     alt="Needs 03"/>
                            </div>
                        </div>
                    </div>
                    <p className="why-coin-laundry-footer-text">コインランドリーへのニーズは<br className="sp"/>今後も継続して拡大。<br />
                        多くの社会課題を解決しながら<br className="sp"/>事業性を維持できるビジネスです。</p>
                    <h3 className="title-wrap">
                        <span className="title">1-2 低リスクな事業</span><span className="sub">Low Risk Business</span>
                        <hr className="title-underline" />
                    </h3>
                    <p className="description">
                        コインランドリー事業において労務管理や人件費が最小限となるよう運営サポートいたします。<br />
                        基本的に洗剤以外は仕入れ／在庫が発生せず、ランニングコストが少ないため、他業種と比較して経営リスクが低い事業形態となっています。日常生活に根差したビジネスであるため、景気による売上の変動も少なく安定した収益を維持することができます。
                    </p>
                    <h4 className="sub-title">OKULABランドリーFCの収支モデル</h4>
                    <div className="description">
                        Baluko Laundry Place出店3年目の収支を想定したモデルケース
                        <div className="small-text">＊売上を保証するものではありません。</div>
                    </div>
                    <div className="sub-contents">
                        <div className="sub-contents-lead-wrap">
                            初期投資額：2,650万円〜4,500万円
                            <div className="small-text">※FC保証金を除く</div>
                            <div className="summary-text">機器投資：1,500万円〜｜工事費：900万円〜｜その他：250万円〜</div>
                        </div>
                        <div className="price-table">
                            <div className="row">
                                <div className="title">年間売上</div>
                                <div className="value">1,200万円</div>
                            </div>
                            <div className="row">
                                <div className="title">年間コスト</div>
                                <div className="value">800万円</div>
                            </div>
                            <div className="row">
                                <div className="title">利益</div>
                                <div className="value">400万円</div>
                            </div>
                            <div className="row">
                                <div className="title">利回り（利益／投資）</div>
                                <div className="value">約10％〜14%</div>
                            </div>
                        </div>
                        <p className="caption">
                            年間コストには光熱費、賃料、運営管理費、売却資産税、販促費用、その他固定費を含みます。
                        </p>
                    </div>

                </div>
                <div className="space"></div>
            </div>
        </section>
    )
}
export default WhyCoinLaundry
