import React from "react"

const WhyOkulab = props => {
    return (
        <section className="why-okulab-wrap">
            <div className="why-okulab-header-wrap">
                <p className="number">2</p>
                <h2>なぜOKULABか？</h2>
                <div className="space"></div>
            </div>
            <div className="why-okulab-body-wrap">
                <div className="space"></div>
                <div className="contents-body-wrap">
                    <h3 className="title-wrap">
                        <span className="title">2-1 OKULABの強み</span><span className="sub">Our Strength</span>
                        <hr className="title-underline" />
                        <p className="description">
                            OKULABは2016年の創業以来、ランドリーを通してお客さまの日常をより豊かなものにするため、他のランドリーにはない品質・安心感・居心地の良さを追求してまいりました。 私たちのコインランドリーブランド、Baluko Laundry Placeは、今までコインランドリーに足を運ぶことのなかった新しいお客さまを含む、多くのお客さまから支持されるブランドに成長しています。
                        </p>
                    </h3>
                    <img className="okulab-team-image pc"
                         src="https://stgokulabweb.blob.core.windows.net/images/services/fc/fc_our-strength.png"
                         alt="Our Strong Point"/>
                    <img className="okulab-team-image sp"
                         src="https://stgokulabweb.blob.core.windows.net/images/services/fc/fc_our_strength_sp.svg"
                         alt="Our Strong Point"/>
                    <h3 className="title-wrap">
                        <span className="title">2-2 Balukoの品質・顧客体験</span><br className="sp" /><span className="sub ml-0-sp">Quality and Customer UX</span>
                        <hr className="title-underline" />
                        <p className="description">
                            Baluko Laundry Placeは洗濯品質、空間デザイン、UX設計すべての面で他のランドリーにはない圧倒的な顧客体験を目指し設計されています。
                        </p>
                    </h3>
                    <div className="baluko-content-wrap">
                        <div className="baluko-content">
                            <img src="https://stgokulabweb.blob.core.windows.net/images/services/fc/fc_balko01.jpg"
                                 alt="Baluko 1" />
                            <h4 className="title">空間デザイン</h4>
                            <p>ファミリーや女性層を始めとした新しい利用者も入りやすく、使いやすいユーザー目線の店舗設計。デザイン性の高い、清潔で居心地のいい空間づくりを追求します。</p>
                        </div>
                        <div className="baluko-content">
                            <img src="https://stgokulabweb.blob.core.windows.net/images/services/fc/fc_balko02.jpg"
                                 alt="Baluko 2" />
                            <h4 className="title">バルコアプリ</h4>
                            <p>キャッシュレス決済・アプリ操作・オンラインでの稼働状況確認など「今までのコインランドリー」のイメージを一新する最新のUIを導入。世代を問わず安心、便利に使えるランドリーを実現しています。</p>
                        </div>
                        <div className="baluko-content">
                            <img src="https://stgokulabweb.blob.core.windows.net/images/services/fc/fc_balko03.jpg"
                                 alt="Baluko 3" />
                            <h4 className="title">オリジナルコース</h4>
                            <p>布団洗濯のニーズが高まる中、より洗浄力を高めるために羽毛布団の洗濯に最適な水量、強度を設定した羽毛布団専用コースを提供しています。</p>
                        </div>
                    </div>
                    <div className="peu-content-wrap">
                        <img src="https://stgokulabweb.blob.core.windows.net/images/services/fc/fc_peu.jpg"
                             alt="Peu"
                             className="sp"/>
                        <div className="text-wrap">
                            <p className="sub">
                                衣類に、人に、環境にやさしく洗う。
                            </p>
                            <h4 className="title">
                                オリジナル洗濯洗剤&柔軟剤<br />
                                「peu（ピウ）」
                            </h4>
                            <p className="body">
                                「汚れをしっかり落とし、余計な成分を衣類に残さないこと」「人や環境への影響に配慮すること」を大切にし、開発したオリジナル洗剤・柔軟剤です。香料・着色料も不使用。すっきりとした洗い上がりで、衣類本来の表情に触れることができます。
                            </p>
                        </div>
                        <img src="https://stgokulabweb.blob.core.windows.net/images/services/fc/fc_peu.jpg"
                             alt="Peu"
                            className="pc"/>
                    </div>
                    <h3 className="title-wrap">
                        <span className="title">2-3 Balukoのマーケティング</span><span className="sub">Marketing</span>
                        <hr className="title-underline" />
                        <p className="description">
                            ブランディング、出店計画、プロモーション、CRMに自社データとサードパーティーデータを活用。<br />
                            デジタルメディアを活用したコミュニケーションで、若年層にも認知を拡大しています。
                        </p>
                    </h3>
                    <div className="marketing-content-wrap">
                        <div className="marketing-content">
                            <div className="image-wrap">
                                <img src="https://stgokulabweb.blob.core.windows.net/images/services/fc/fc_marketing01.png"
                                     alt="Marketing 1" />
                                <p className="caption">商圏分析資料</p>
                            </div>
                            <div className="text-wrap">
                                <h4 className="title">出店・商圏調査</h4>
                                <p className="body">人口・商圏データにくわえ、年間1,000以上行う立地調査と1,000件以上のコインランドリー分析をもとにした、独自のスコアリングにより、事業性の高いロケ－ションをご提案いたします。</p>
                            </div>
                        </div>
                        <div className="marketing-content">
                            <div className="image-wrap">
                                <img src="https://stgokulabweb.blob.core.windows.net/images/services/fc/fc_marketing02.jpg"
                                     alt="Marketing 2" />
                                <p className="caption">Nスタ（TBSテレビ）</p>
                            </div>
                            <div className="text-wrap">
                                <h4 className="title">PR</h4>
                                <p className="body">当社新規事業やサービスの拡大など、戦略的にプレスリリースを配信。地上波キ－局含めてＴＶ、雑誌、Web メディアにて、多数ののご紹介いただいています。また、地域のお客さまと、洗濯をテーマにしたイベントを定期的に開催。お客さまとの信頼関係を深めるとともに洗濯に対する意識を高める情報を発信しています。</p>
                            </div>
                        </div>
                        <div className="marketing-content">
                            <div className="image-wrap">
                                <img src="https://stgokulabweb.blob.core.windows.net/images/services/fc/fc_marketing03.png"
                                     alt="Marketing 3" />
                            </div>
                            <div className="text-wrap">
                                <h4 className="title">認知・販促</h4>
                                <p className="body">近年のコインランドリー集客で重要な、Googleマイビジネスの運用をはじめFacebook、Instagram、Twitterでのオーガニック投稿、広告配信を実施しています。売上データ、顧客データ、気象データ、広告配信データを分析し、出店後の認知獲得から季節ごとのプロモーションまで、年間プロモーションプランを策定し、提供しています。</p>
                            </div>
                        </div>
                    </div>
                    <h3 className="title-wrap">
                        <span className="title">2-4 Balukoの店舗運営</span><span className="sub">Store Management</span>
                        <hr className="title-underline" />
                        <p className="description">
                            清掃、集金、コールセンターに加えて機器メンテナンスなど店舗運営に必要なサービスを提供しております。<br />
                            その他販促支援、故障トラブル対応などのサポートサービスもご用意しております。
                        </p>
                    </h3>
                    <div className="store-management-wrap">
                        <div className="store-management-header">
                            <div className="title-wrap">
                                <h4>Baluko Pack<br className="sp" />（店舗運営サポートパック)</h4>
                            </div>
                            <div className="price-wrap">
                                89,000円～（月額）
                            </div>
                        </div>
                        <div className="baluko-pack-items">
                            <div className="item">
                                <div className="image-wrap">
                                    <img src="https://stgokulabweb.blob.core.windows.net/images/services/fc/baluko-pack-item01.svg"
                                         alt="Clean" />
                                </div>
                                <h5>清掃</h5>
                                <p>1日1回の頻度で清掃実施<br />（店内・機器清掃）</p>
                            </div>
                            <div className="item">
                                <div className="image-wrap">
                                    <img src="https://stgokulabweb.blob.core.windows.net/images/services/fc/baluko-pack-item02.svg"
                                         alt="Money" />
                                </div>
                                <h5>集金</h5>
                                <p>売上金回収及び釣銭補充<br />（回数は売上規模に準じます）</p>
                            </div>
                            <div className="item">
                                <div className="image-wrap">
                                    <img src="https://stgokulabweb.blob.core.windows.net/images/services/fc/baluko-pack-item03.svg"
                                         alt="Call" />
                                </div>
                                <h5>コールセンター</h5>
                                <p>24時間365日<br />問い合わせ対応</p>
                            </div>
                            <div className="item">
                                <div className="image-wrap">
                                    <img src="https://stgokulabweb.blob.core.windows.net/images/services/fc/baluko-pack-item04.svg"
                                         alt="Gear" />
                                </div>
                                <h5>機器メンテナンス</h5>
                                <p>機器トラブル時の対応<br />（部品代は別途となります）</p>
                            </div>
                        </div>
                        <p className="baluko-pack-notification">＊販促支援・洗剤・備品・消耗品の補充、返金・トラブル時のお客様対応、その他緊急対応は別途費用（ご相談）となります。</p>
                    </div>
                    <h3 className="title-wrap">
                        <span className="title">2-5 人材・組織</span><span className="sub">Our Team</span>
                        <hr className="title-underline" />
                        <p className="description">
                            コインランドリー業界機器最大手のハイアールグループ（アクア）の元事業責任者、技術開発責任者が創設したOKULAB。<br />
                            ビジネス、店舗設計、デザイン、IT、メカニカル・エンジニアリング、マーケティングのスペシャリストが集い、他のランドリーと一線を画する顧客価値を創造しています。
                        </p>
                    </h3>
                    <div className="org-table">
                        <div className="org-header">
                            <div className="column">対応項目</div>
                            <div className="value">体制</div>
                        </div>
                        <div className="row">
                            <div className="column">物件開発</div>
                            <div className="value">商圏分析含む物件リサーチ・選定の専門チーム</div>
                        </div>
                        <div className="row">
                            <div className="column">店舗デザイン・<br className="sp"/>設計</div>
                            <div className="value">店舗設計の専門チーム（一級建築士在籍）、店舗サインデザイナー</div>
                        </div>
                        <div className="row">
                            <div className="column">施工・監理</div>
                            <div className="value">社内施工監理チームのもとコインランドリー施工の経験が豊富な企業と提携（全国対応可能）</div>
                        </div>
                        <div className="row">
                            <div className="column">機器設置・<br className="sp"/>メンテナンス</div>
                            <div className="value">アクア社出身の技術開発者の指導を受けたメカニカルエンジニアチーム</div>
                        </div>
                        <div className="row">
                            <div className="column">店舗運営・<br className="sp"/>管理</div>
                            <div className="value">店舗管理・カスタマーサポート・緊急時対応の専任チーム、清掃・コールセンター等各種提携企業</div>
                        </div>
                        <div className="row">
                            <div className="column">マーケティング</div>
                            <div className="value">販促企画専門チーム、グラフィックデザイナー、データアナリスト</div>
                        </div>
                    </div>
                </div>
                <div className="space"></div>
            </div>
        </section>
    )
}
export default WhyOkulab
